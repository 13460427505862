<template>
    <v-dialog v-model="viewOps.dialog" max-width="550px">
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col cols="10">
                        <span>View Tracker</span>
                    </v-col>
                    <v-col cols="2">
                        <div class="float-right">
                            <CustomButton
                                btnIcon="mdi-close"
                                tooltipMsg="Close"
                                @click="hideDialog"
                            />
                        </div>
                    </v-col>
                </v-row>
            </v-card-title>

            <v-divider />

            <v-card-text class="px-4">
                <v-card flat>
                    <v-card-text>
                        <ValidationObserver ref="observer" v-slot="{}">
                            <v-form>
                                <v-row dense>
                                    <v-col>
                                        <CustomLabel
                                            for="start_date"
                                            label="Start Date"
                                        />
                                        <CustomTextField
                                            id="start_date"
                                            inputDisabled
                                            inputPlaceholder=""
                                            :inputValue="
                                                formatDate(
                                                    tracker.datetime_start
                                                )
                                            "
                                        />
                                    </v-col>

                                    <v-col>
                                        <CustomLabel
                                            for="start_time"
                                            label="Start Time"
                                        />
                                        <CustomTextField
                                            id="start_time"
                                            inputDisabled
                                            inputPlaceholder=""
                                            :inputValue="
                                                formatTime(
                                                    tracker.datetime_start
                                                )
                                            "
                                        />
                                    </v-col>
                                </v-row>

                                <v-row dense>
                                    <v-col>
                                        <CustomLabel
                                            for="end_date"
                                            label="End Date"
                                        />
                                        <CustomTextField
                                            id="end_date"
                                            inputDisabled
                                            inputPlaceholder=""
                                            :inputValue="
                                                formatDate(tracker.datetime_end)
                                            "
                                        />
                                    </v-col>

                                    <v-col>
                                        <CustomLabel
                                            for="end_time"
                                            label="End Time"
                                        />

                                        <CustomTextField
                                            id="end_time"
                                            inputDisabled
                                            inputPlaceholder=""
                                            :inputValue="
                                                formatTime(tracker.datetime_end)
                                            "
                                        />
                                    </v-col>
                                </v-row>

                                <v-row dense>
                                    <v-col>
                                        <CustomLabel
                                            for="category"
                                            label="Category"
                                        />
                                        <CustomTextField
                                            id="category"
                                            inputDisabled
                                            inputPlaceholder=""
                                            :inputValue="tracker.category.name"
                                        />
                                    </v-col>
                                </v-row>

                                <v-row dense>
                                    <v-col>
                                        <CustomLabel
                                            for="customer"
                                            label="Customer"
                                        />
                                        <CustomTextField
                                            id="customer"
                                            inputDisabled
                                            inputPlaceholder=""
                                            :inputValue="tracker.customer.name"
                                        />
                                    </v-col>
                                </v-row>

                                <v-row dense>
                                    <v-col>
                                        <CustomLabel
                                            for="client"
                                            label="Client"
                                        />
                                        <CustomTextField
                                            id="client"
                                            inputDisabled
                                            inputPlaceholder=""
                                            :inputValue="
                                                tracker.client == null
                                                    ? ''
                                                    : tracker.client.name
                                            "
                                        />
                                    </v-col>
                                </v-row>

                                <v-row dense>
                                    <v-col>
                                        <CustomLabel for="task" label="Task" />
                                        <CustomTextField
                                            id="task"
                                            inputDisabled
                                            inputPlaceholder=""
                                            :inputValue="
                                                tracker.task == null
                                                    ? ''
                                                    : tracker.task.name
                                            "
                                        />
                                    </v-col>
                                </v-row>

                                <v-row dense>
                                    <v-col>
                                        <CustomLabel
                                            for="description"
                                            label="Description"
                                        />
                                        <CustomTextAreaField
                                            id="description"
                                            inputDisabled
                                            inputPlaceholder=""
                                            :inputValue="tracker.description"
                                        />
                                    </v-col>
                                </v-row>

                                <v-row class="mt-4">
                                    <v-col>
                                        <CustomButton
                                            btnLabel="Close"
                                            tooltipMsg="Close"
                                            @click="hideDialog"
                                        />
                                        <CustomButton
                                            btnColour="error"
                                            btnLabel="Remove"
                                            :btnIsLoading="removeOps.isLoading"
                                            tooltipMsg="Remove"
                                            @click="removeItem(tracker)"
                                        />
                                    </v-col>
                                </v-row>
                            </v-form>
                        </ValidationObserver>
                    </v-card-text>
                </v-card>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { DateTime } from "luxon";

extend("required", {
    ...required,
    message: "Field cannot be empty.",
});

export default {
    computed: {
        ...mapState("trackersUserMgmt", ["tracker", "viewOps", "removeOps"]),
    },
    data: () => ({}),
    methods: {
        formatDate(dateTime) {
            return DateTime.fromFormat(dateTime, "yyyy-LL-dd HH:mm").toFormat(
                "yyyy-LL-dd"
            );
        },
        formatTime(dateTime) {
            return DateTime.fromFormat(dateTime, "yyyy-LL-dd HH:mm").toFormat(
                "HH:mm"
            );
        },
        hideDialog() {
            this.$store.commit("trackersUserMgmt/HIDE_VIEW_OPS_DIALOG");
            this.$store.commit("trackersUserMgmt/FLUSH_ITEM");
        },
        removeItem(payload) {
            this.$store.dispatch("trackersUserMgmt/removeItem", payload);
        },
        updateForm(event, form) {
            if (event instanceof InputEvent) {
                this.$store.commit("trackersUserMgmt/UPDATE_FORM", {
                    payload: {
                        form: form,
                        value: event.target.value,
                    },
                });
                return;
            } else if (
                event instanceof String ||
                typeof event == "boolean" ||
                typeof event == "number" ||
                typeof event == "string"
            ) {
                this.$store.commit("trackersUserMgmt/UPDATE_FORM", {
                    payload: {
                        form: form,
                        value: event,
                    },
                });
                return;
            } else {
                return;
            }
        },
    },
    name: "DialogTrackerView",
    // Lifecycle hooks.
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
};
</script>
