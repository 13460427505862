var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"550px"},model:{value:(_vm.viewOps.dialog),callback:function ($$v) {_vm.$set(_vm.viewOps, "dialog", $$v)},expression:"viewOps.dialog"}},[_c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('span',[_vm._v("View Tracker")])]),_c('v-col',{attrs:{"cols":"2"}},[_c('div',{staticClass:"float-right"},[_c('CustomButton',{attrs:{"btnIcon":"mdi-close","tooltipMsg":"Close"},on:{"click":_vm.hideDialog}})],1)])],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"px-4"},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('v-form',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('CustomLabel',{attrs:{"for":"start_date","label":"Start Date"}}),_c('CustomTextField',{attrs:{"id":"start_date","inputDisabled":"","inputPlaceholder":"","inputValue":_vm.formatDate(
                                                _vm.tracker.datetime_start
                                            )}})],1),_c('v-col',[_c('CustomLabel',{attrs:{"for":"start_time","label":"Start Time"}}),_c('CustomTextField',{attrs:{"id":"start_time","inputDisabled":"","inputPlaceholder":"","inputValue":_vm.formatTime(
                                                _vm.tracker.datetime_start
                                            )}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('CustomLabel',{attrs:{"for":"end_date","label":"End Date"}}),_c('CustomTextField',{attrs:{"id":"end_date","inputDisabled":"","inputPlaceholder":"","inputValue":_vm.formatDate(_vm.tracker.datetime_end)}})],1),_c('v-col',[_c('CustomLabel',{attrs:{"for":"end_time","label":"End Time"}}),_c('CustomTextField',{attrs:{"id":"end_time","inputDisabled":"","inputPlaceholder":"","inputValue":_vm.formatTime(_vm.tracker.datetime_end)}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('CustomLabel',{attrs:{"for":"category","label":"Category"}}),_c('CustomTextField',{attrs:{"id":"category","inputDisabled":"","inputPlaceholder":"","inputValue":_vm.tracker.category.name}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('CustomLabel',{attrs:{"for":"customer","label":"Customer"}}),_c('CustomTextField',{attrs:{"id":"customer","inputDisabled":"","inputPlaceholder":"","inputValue":_vm.tracker.customer.name}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('CustomLabel',{attrs:{"for":"client","label":"Client"}}),_c('CustomTextField',{attrs:{"id":"client","inputDisabled":"","inputPlaceholder":"","inputValue":_vm.tracker.client == null
                                                ? ''
                                                : _vm.tracker.client.name}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('CustomLabel',{attrs:{"for":"task","label":"Task"}}),_c('CustomTextField',{attrs:{"id":"task","inputDisabled":"","inputPlaceholder":"","inputValue":_vm.tracker.task == null
                                                ? ''
                                                : _vm.tracker.task.name}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('CustomLabel',{attrs:{"for":"description","label":"Description"}}),_c('CustomTextAreaField',{attrs:{"id":"description","inputDisabled":"","inputPlaceholder":"","inputValue":_vm.tracker.description}})],1)],1),_c('v-row',{staticClass:"mt-4"},[_c('v-col',[_c('CustomButton',{attrs:{"btnLabel":"Close","tooltipMsg":"Close"},on:{"click":_vm.hideDialog}}),_c('CustomButton',{attrs:{"btnColour":"error","btnLabel":"Remove","btnIsLoading":_vm.removeOps.isLoading,"tooltipMsg":"Remove"},on:{"click":function($event){return _vm.removeItem(_vm.tracker)}}})],1)],1)],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }